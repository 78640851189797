<template>
    <div class="d-flex align-items-baseline user-rating">
        <div class="text-s-bold text-15" style="color: var(--palette-gold);">
            {{ getTwoDigits(rating) }}
        </div>
        <div class="text-9d9d9d text-15">/5</div>
    </div>
</template>
<script>
import common from '@/services/common'

export default {
    name: 'UserRating',
    props: {
        rating: {
            type: Number,
            required: true
        }
    },
    computed: {},
    methods: {
        getTwoDigits(str) {
            if (str) {
                return common.twoDigits(str)
            }
            return str
        }
    }
}
</script>
<style scoped>
.user-rating {
    user-select: none;
}
</style>

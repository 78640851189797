<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="18"
        viewBox="0 -960 960 960"
        width="18"
    >
        <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
    </svg>
</template>
<script>
export default {
    name: 'ArrowForwardIOS'
}
</script>

<template>
    <div @click.prevent.stop v-if="locationName" class="d-flex flex-wrap align-items-center">
        <LocationIcon :is-add-margin="true" v-if="!isHideIcon" />
        <router-link
            v-if="listItem?.location?.area?.has_page && !isHideLocationLink"
            :to="computedMapLink"
        >
            <TextButton class="space-area active-space-area text-13">
                {{ locationName }}
            </TextButton>
        </router-link>
        <div v-else class="space-area text-9d9d9d text-13">
            {{ locationName }}
        </div>
    </div>
</template>

<script>
import LocationIcon from '@/components/iconSvg/Location'
import TextButton from '@/components/item/TextButton'
import {getGAEventName} from "@/utils/analysis";

export default {
    name: 'SpaceCardLocation',
    components: {
        TextButton,
        LocationIcon
    },
    props: {
        algoliaLang: {
            type: String,
            required: true
        },
        listItem: {
            type: Object,
            required: true
        },
        isHideLocationLink: {
            type: Boolean,
            required: true
        },
        isHideIcon: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'OTHER'
        }
    },
    computed: {
        keyword() {
            return this.$route.params.keyword
        },
        space() {
            return this.$route.params.space
        },
        locationName() {
            return this.listItem?.location?.area?.name?.[this.algoliaLang]
        },
        computedMapLink() {
            const locationSlug = this.listItem?.location?.area?.slug
            const tail = this.keyword ?? 'maps'
            return {
                name: 'region-locale-space-location-keyword',
                params: {
                    space: this.space,
                    source: getGAEventName(this.type,'SPACE_CARD','LOCATION_NAME'),
                    location: locationSlug,
                    keyword: tail
                }
            }
        }
    }
}
</script>

<style scoped>
.space-area {
    white-space: nowrap;
}
</style>

<template>
    <div class="w-100">
        <div
            :class="`swiper-container ${type}-swiper-container-${name} h-100`"
            style="overflow: hidden; cursor: pointer;"
        >
            <div
                :id="`${type}-${name}-swiper-wrapper`"
                class="swiper-wrapper d-flex"
            >
                <slot />
            </div>
            <div v-if="swiperPagination" class="swiper-pagination" />
            <div v-if="swiperButton">
                <div class="swiper-button-prev" @click.prevent.stop>
                    <ArrowBackIOS style="width: 14px; height: 14px;" />
                </div>
                <div class="swiper-button-next" @click.prevent.stop>
                    <ArrowForwardIOS style="width: 14px; height: 14px;" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SwiperCore, { Autoplay, Navigation, Pagination, Swiper } from 'swiper'
import 'swiper/swiper-bundle.css'
import ArrowBackIOS from '@/components/iconSvg/ArrowBackIOS.vue'
import ArrowForwardIOS from '@/components/iconSvg/ArrowForwardIOS.vue'
import _isEmpty from "lodash/isEmpty";
import analysis from "@/services/analysis";
import {getGAEventName} from "@/utils/analysis";
SwiperCore.use([Autoplay, Navigation, Pagination])
export default {
    name: 'SwiperContainer',
    components: { ArrowForwardIOS, ArrowBackIOS },
    props: {
        option: {
            type: Object,
            default() {
                return {}
            }
        },
        swiperButton: {
            type: Boolean,
            default: false
        },
        swiperPagination: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: 'SwiperContainer'
        },
        markerIndex: {
            type: Number,
            default: 0
        },
        type: {
            type: String,
            default: ''
        },
        isSetSlidesPerView: {
            type: Boolean,
            default: false
        },
        isInitTransition: {
            type: Boolean,
            default: false
        },
        setMargin: {
            type: Boolean,
            default: false
        },
        widthAdjustment: {
            type: Number,
            default: 0
        },
        isFreeMode: {
            type: Boolean,
            default: false
        },
        isSlidesOffset: {
            type: Boolean,
            default: false
        },
        isAllowTouchMove: {
            type: Boolean,
            default: true
        },
        setSlideSpeed: {
            type: Number,
            default: 0
        },
        isSendGAEvent: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            swiperOption: {},
            defaultOption: {
                mode: 'horizontal',
                freeMode: this.isFreeMode,
                allowTouchMove: !this.swiperButton && this.isAllowTouchMove
            },
            swiper: {}
        }
    },
    computed: {},
    watch: {
        markerIndex(newVal) {
            if (newVal >= 0) {
                this.swiper.slideTo(newVal, this.setSlideSpeed, false)
            }
        }
    },
    mounted() {
        if (this.isSetSlidesPerView) this.setSlidesPerView()
        this.setSwiperOption()
        /* eslint-disable no-unused-vars */
        this.swiper = new Swiper(
            `.${this.type}-swiper-container-${this.name}`,
            this.swiperOption
        )
        if (!_isEmpty(this.swiper) && !this.swiper.length) {
            this.swiper.on('slideChange', () => {
                this.slideChange(this.swiper.activeIndex)
            })
            if (this.isInitTransition) {
                this.swiper.slideTo(6)
                setTimeout(() => {
                    this.swiper.slideTo(0)
                }, 1000)
            }
        }
    },
    methods: {
        setSlidesPerView() {
            const swiperSlideElement = document.getElementById(
                `${this.type}-${this.name}-swiper-slide`
            )
            if (swiperSlideElement) {
                let slideWidth = swiperSlideElement.clientWidth
                // add one px for border
                slideWidth += this.widthAdjustment
                const wrapperWidth = document.getElementById(
                    `${this.type}-${this.name}-swiper-wrapper`
                ).clientWidth
                let margin = 10
                if (wrapperWidth > 1799 && this.setMargin) margin = 20
                const marginNum = Math.floor(
                    wrapperWidth / (slideWidth + margin)
                )
                this.defaultOption.slidesPerView =
                    (wrapperWidth - margin * marginNum) / slideWidth
                // console.log(
                //     `slideWidth:${slideWidth}, wrapperWidth:${wrapperWidth}`
                // )
            }
        },
        setSwiperOption() {
            const pagination = {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: false,
                dynamicBullets: false,
                renderBullet(index, className) {
                    return `<span class="${className} swiper-pagination-bullet-custom"></span>`
                }
            }
            const navigation = {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
            if (this.swiperButton) this.defaultOption.navigation = navigation
            if (this.swiperPagination)
                this.defaultOption.pagination = pagination
            this.swiperOption = { ...this.option, ...this.defaultOption }
        },
        slideChange(index) {
            if (this.isSendGAEvent) {
                const source = getGAEventName(this.landingPageName,'SPACE_CARD', undefined, index, this.name)
                analysis.sendScrollPhotos(source)
            }
            this.$emit('setCurrentSlide', index)
        }
    }
}
</script>

<style scoped>
.swiper-pagination >>> .swiper-pagination-bullet {
    background-color: rgba(209, 209, 209, 0.8);
    opacity: 1;
}
.swiper-pagination >>> .swiper-pagination-bullet-active {
    background-color: white;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    display: none;
}

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-color: #fff;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
    opacity: 1 !important;
}
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
    content: '';
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
    content: '';
}

.swiper-container:hover .swiper-button-prev,
.swiper-container:hover .swiper-button-next {
    opacity: 0.9;
}

.swiper-button-prev,
.swiper-button-next {
    opacity: 0;
    transition: opacity 0.3s ease;
}
</style>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="18"
        viewBox="0 -960 960 960"
        width="18"
    >
        <path d="M640-80 240-480l400-400 71 71-329 329 329 329-71 71Z" />
    </svg>
</template>
<script>
export default {
    name: 'ArrowBackIOS'
}
</script>

<template>
    <div
        v-if="price && price !== 0"
        class="d-flex text-404040 align-items-baseline"
    >
        <div class="text-bold">{{ price }}</div>
        <div
            v-if="spaceType === 'party'"
            class="text-14 text-bold text-dark"
            style="white-space: nowrap;"
        >
            {{ `/${$t('details.hr')}` }}
        </div>
        <div
            v-if="spaceType === 'party' && isRoomPrice"
            class="text-13 ml-1"
            style="white-space: nowrap;"
        >
            {{ `(${$t('details.whole_unit')})` }}
        </div>
        <div class="text-12 ml-1 ml-lg-1 text-bold">
            {{ $t('details.up') }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'MinPrice',
    props: {
        spaceType: {
            type: String,
            default: ''
        },
        price: {
            type: String,
            default: ''
        },
        isRoomPrice: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<template>
    <div class="d-flex align-items-baseline text-9d9d9d">
        <div class="text-13 total-comment">(</div>
        <div class="text-13 total-comment">
            {{ comments }}
        </div>
        <div class="text-13 total-comment">)</div>
    </div>
</template>
<script>
import common from '@/services/common'

export default {
    name: 'TotalComment',
    props: {
        comments: {
            type: Number,
            required: true
        }
    },
    computed: {},
    methods: {
        getTwoDigits(str) {
            if (str) {
                return common.twoDigits(str)
            }
            return str
        }
    }
}
</script>
<style scoped>
.total-comment {
    user-select: none;
}
</style>

<template>
    <div>
        <h2
            v-if="type === 'mobile'"
            class="text-19 text-s-bold text-3c3c3c mb-0"
        >
            <slot />
        </h2>
        <p v-else class="text-19 text-s-bold text-3c3c3c mb-0">
            <slot />
        </p>
    </div>
</template>
<script>
export default {
    name: 'HeaderTitle',
    props: {
        type: {
            required: false,
            type: String,
            default: 'mobile'
        }
    }
}
</script>
